import React from "react"
import siteCardImage from "../../static/card.jpg"
import Seo from "../components/seo"
import { navigate } from "gatsby"

const shareCardGenerel = {
  src: siteCardImage,
  width: 1200,
  height: 628,
}

const App = () => {
  if (typeof window !== "undefined") navigate("/blog")
  return (
    <>
      <Seo
        title="مدونة بندر"
        image={shareCardGenerel}
        description="مدونة شخصية تهتم بموضوعات متنوعة وعلى رأسها تطوير واجهات الويب والموبايل وتصميم تجربة المستخدم إلى جانب استعراض دوري لبعض الكتب المفيدة."
        url="/"
      />
    </>
  )
}

export default App
